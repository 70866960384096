html, body {
  min-width: 235px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.player-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.react-player {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
  border: none;
  pointer-events: none;
  user-select: none;
}

.shopNowLink {
  display: block;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  left: 75px;
  top: 75px;
  font-size: 50px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
}

.bodyContentParagraphFillerLine {
  position: absolute;
  top: 80dvh;
  left: 50%;
  background-color: rgba(0, 0, 0, .25);
  padding: 7px;
  border-radius: 10px;
  font-size: 1.8em;
  color: white;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

@font-face {
  font-family: "SlimJoe";
  src: url("fonts/Slim Joe.woff2") format("woff2");
}

@font-face {
  font-family: "BigJohn";
  src: url("fonts/BIG JOHN.woff2") format("woff2");
}

@font-face {
  font-family: "Acumin";
  src: url("fonts/Acumin-RPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Acumin";
  src: url("fonts/Acumin-ItPro.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Acumin";
  src: url("fonts/Acumin-BdPro.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Acumin";
  src: url("fonts/Acumin-BdItPro.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

div.menuDiv {
  font-family: "SlimJoe", sans-serif;
  font-size: 21pt;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

div.menuContainerDiv {
  width: 900px;
  margin-left: 1.5dvw;
  margin-right: 1.5dvw;
  margin-top: .6dvw;
  margin-bottom: .6dvw;
}

div.imageCaption {
  width: 39%;
}

@media screen and (max-width: 900px) {
  div.menuContainerDiv {
    width: 100%;
  }
  div.menuDiv {
    font-size: 3dvw;
  }
}

@media screen and (max-width: 620px) {
  div.imageCaption {
    width: 80%;
  }
}

@media screen and (max-width: 670px) {
  .bodyContentParagraphFillerLine {
    top: 75dvh;
  }
}

@media screen and (max-width: 570px) {
  .bodyContentParagraphFillerLine {
    top: 70dvh;
  }
}

@media screen and (max-width: 494px) {
  .bodyContentParagraphFillerLine {
    top: 60dvh;
    font-size: 1.8em;
  }
}

@media screen and (max-width: 460px) {
  .shopNowLink {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 360px) {
  .shopNowLink {
    white-space: unset;
  }
}
